import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import React, { useEffect } from "react";

import { house1 } from "../../constants/ImagePath";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
const Resources = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${house1})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          height: { xs: "40vh", md: "50vh", lg: "60vh" },
          backgroundAttachment: "scroll",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5, mt: 3 }}
            variant="h2"
          >
            FAQS
          </Typography>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "rgba(0,0,0,0.06)", py: 15 }}>
        <Container maxWidth="lg">
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  What is a beginner’s mistake when building a new home?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  The biggest error most people make is overspending –
                  underestimating the costs of the build. We’ve outlined the
                  best way
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  How can I keep the building project on budget?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  The only way to guarantee your project within your budget is
                  to enter into a full contract, fixed price agreement with your
                  builder. Then, review all prime cost sums and decide if you’re
                  likely to spend more on these items than has been allowed in
                  the budget. The costs for cabinetry and appliances, for
                  example, vary hugely.
                </Typography>

                <Typography sx={{ my: 2 }}>
                  Some extra costs that won’t be included in a quote are
                  landscaping and lawns, washing lines, letterboxes, curtains
                  and blinds. These items can add up very quickly.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  How do I assess the builder’s workmanship?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  The best way is to check the builder’s previous and current
                  projects – if they’re not comfortable showing you these,
                  that’s a bad sign. Check their health and safety policy, and
                  how clean and tidy they run their current sites. Wen view
                  previous projects check for details such as wobbly fittings or
                  gaps in skirting or doors.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  I have my own land and want to build a new house. Can you
                  help?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Yes, we can assist you in deciding what to build, then partner
                  you through the whole journey.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  I want to knock down my house and rebuild. What’s next?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Talk to us – we can take care of the whole process from
                  consents, to new construction.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  We want to subdivide our existing property. Where do I start?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Chat to us. A subdivision can be tricky and complicated. We
                  have a team of surveyors, architect and engineers to make your
                  planning successful and execution even better.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  Are there any size requirements for subdivision?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  There are no minimum requirements to subdivide and build in
                  Auckland. It’s more about your end goal – what you want to do
                  with the final building.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  Do your prices include GST?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>Yes, our prices do include GST.</Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  What does your detailed quote include?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  You get a realistic quote for your project. This means you pay
                  what’s agreed, unless you ask to make changes along the way.
                  Creating this quote involves conducting detailed analysis of
                  your plan and requirements. This detailed quote becomes the
                  part of a legal and binding contract so you don’t get nasty
                  surprises.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  We are ready to start. What’s next?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  We prepare a formal contract with an agreement on the initial
                  deposit and start date, so we can schedule one dedicated
                  project manager working on your home.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  How do payments work?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Payment options depend on the type of contract you have
                  chosen. With a full contract you only make payments to one
                  company, at specific stages of the b build. You’ll get a
                  payment schedule from us, so you know what you’ll need to pay
                  and when.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  How long until I get into my new home?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  We work on strict timelines and we’ll make sure you understand
                  those from the start. We’ll also agree on start and completion
                  dates so you have a realistic idea of when you can move in.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  Can I choose an existing design for the house?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Absolutely! You can choose from our range of pre-designed
                  plans, however we can also custom design a plan to suit your
                  budget and site.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  Can we input into the interior design?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Yes! This is your home, so we welcome your input in selecting
                  the look and feel of your home. While we can still accommodate
                  variations at a later stage, we strongly recommend selecting
                  everything before construction begins as it saves time and
                  money.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  Can we make changes once construction is started?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Yes, but it generally adds time and expense to the project.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  How do I know what is happening with my new build?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  You’ll be updated throughout by your dedicated project manager
                  and our project director. We provide you a 24-hour access
                  login to the construction software and application to see all
                  details in realtime from your smartphone.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  How does the weather affect the building process?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Weather can delay a project and hampers the construction,
                  however with better planning we can avoid major issues. We
                  spend extra money for Hi and Dri packers on projects being
                  built over winter so we can deliver a watertight home and
                  continue work throughout the winter season. Completing
                  foundations and earthworks before winter is also key.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  Do you offer turnkey packages?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Yes – we can look after the minute details of a project.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  What insurances will I need?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Any reputable builder will hold public liability and
                  contractors all risk insurance for a new build. If work is
                  being done to an existing property, we will discuss the best
                  insurance options with you to protect your existing property.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  What corners are often cut during new home builds?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Quality is very important. Poor builders often win the
                  contracts on lower prices, then cut corners by using
                  lower-quality material, reducing the quantity of materials
                  used and opting for quick and easy fixes.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  What are the essentials for a new home?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  As space for you and your loved ones, the functionality of a
                  home should be as per your lifestyle, while still keeping in
                  mind that resale value.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  How much does it cost to build a new home?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  Lots of factors such as size, specifications, quality are
                  involved. We strongly recommend downloading our free ebook
                  here.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  Are you a registered master builder?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography sx={{ display: "flex" }}>
                  Yes. Every project is covered by a 10-year Master Builders
                  guarantee.&nbsp;
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      color: "primary.main",
                      cursor: "pointer",
                    }}
                  >
                    Click here to see more.
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  Can you build anywhere in Auckland?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  It’s a case by case basis – our goal is always efficiency, so
                  if the location is going to add unnecessary expense, we’ll
                  turn down the project
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ my: 2 }}>
            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: "primary.main", fontSize: "1.5rem" }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "primary.main",
                    fontSize: "2rem",
                    mr: 1,
                  }}
                />
                <Typography variant="h6" fontWeight={600}>
                  Can you start the project as soon as we sign the contract
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
                <Typography>
                  No, we need time for proper onboarding and planning. This
                  takes a minimum of three weeks. We also may need to schedule
                  your project for later to ensure you have a dedicated project
                  manager throughout your entire build.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Resources;
