import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Plant } from "../../constants/ImagePath";

const Privacypolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${Plant})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "70vh",
          backgroundAttachment: "fixed",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5, mt: 3 }}
            variant="h3"
          >
            Privacy Policy
          </Typography>
        </Box>
      </Box>
      <Box sx={{ py: 10 }}>
        <Container maxWidth="lg">
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            If you require any more information or have any questions about our
            privacy policy, please feel free to contact us by email at
            info@pcghomes.co.nz.
          </Typography>
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1"> Log Files </Typography>
            <Typography variant="subtitle1">
              Like many other Web sites, pcghomes.co.nz/ makes use of log files.
              The information inside the log files includes internet protocol (
              IP ) addresses, type of browser, Internet Service Provider ( ISP
              ), date/time stamp, referring/exit pages, and number of clicks to
              analyze trends, administer the site, track user’s movement around
              the site, and gather demographic information. IP addresses, and
              other such information are not linked to any information that is
              personally identifiable.
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">Cookies and Web Beacons</Typography>
            <Typography variant="subtitle1">
              pcghomes.co.nz/ does use cookies to store information about
              visitors preferences, record user-specific information on which
              pages the user access or visit, customize Web page content based
              on visitors browser type or other information that the visitor
              sends via their browser.
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">DoubleClick DART Cookie</Typography>
            <Typography variant="subtitle1">
              .:: Google, as a third party vendor, uses cookies to serve ads on
              pcghomes.co.nz/.
            </Typography>
            <Typography variant="subtitle1">
              .:: Google’s use of the DART cookie enables it to serve ads to
              users based on their visit to pcghomes.co.nz/ and other sites on
              the Internet.
            </Typography>
            <Typography variant="subtitle1">
              .:: Users may opt out of the use of the DART cookie by visiting
              the Google ad and content network privacy policy at the following
              URL –
            </Typography>
            <Typography variant="subtitle1">
              http://www.google.com/privacy_ads.html
            </Typography>
          </Box>

          <Typography variant="subtitle1" sx={{ my: 2 }}>
            Some of our advertising partners may use cookies and web beacons on
            our site. Our advertising partners include …. Google Adsense
          </Typography>
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            These third-party ad servers or ad networks use technology to the
            advertisements and links that appear on pcghomes.co.nz/ send
            directly to your browsers. They automatically receive your IP
            address when this occurs. Other technologies ( such as cookies,
            JavaScript, or Web Beacons ) may also be used by the third-party ad
            networks to measure the effectiveness of their advertisements and /
            or to personalize the advertising content that you see.
          </Typography>
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            pcghomes.co.nz/ has no access to or control over these cookies that
            are used by third-party advertisers.
          </Typography>
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            You should consult the respective privacy policies of these
            third-party ad servers for more detailed information on their
            practices as well as for instructions about how to opt-out of
            certain practices. pcghomes.co.nz/’s privacy policy does not apply
            to, and we cannot control the activities of, such other advertisers
            or web sites.
          </Typography>
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            If you wish to disable cookies, you may do so through your
            individual browser options. More detailed information about cookie
            management with specific web browsers can be found at the browsers’
            respective websites.
          </Typography>
        </Container>
      </Box>
    </div>
  );
};

export default Privacypolicy;
