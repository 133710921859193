import { Button, Container, Grid, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  brand1,
  brand10,
  brand11,
  brand12,
  brand13,
  brand14,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,
  brand9,
  contact,
  dining,
  homefamily,
  homepagebanner,
  livingroom,
  proj1,
  proj2,
  proj3,
  whitelogo,
} from "../../constants/ImagePath";
import "./home.css";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const Home = () => {
  let navigate = useNavigate();
  const [state, setstate] = useState({});
  const [modal, setModal] = useState(false);
  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };
  const handleClick = () => {
    setstate({
      name: "",
      phone: "",
      email: "",
      service: "",
      message: "",
    });
    setModal(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${homepagebanner})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          height: { xs: "70vh", lg: "100vh" },
          backgroundAttachment: "fixed",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "60%",
            transform: "translateY(-50%)",
            zIndex: 2,
            left: { xs: "15%", lg: "20%" },
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                fontSize: { xs: 50, lg: 70 },
                lineHeight: 1.2,
              }}
            >
              Quality
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Allura",
                marginLeft: "15%",
                fontSize: { xs: 50, lg: 70 },
                lineHeight: 1.2,
              }}
            >
              and
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                fontSize: { xs: 50, lg: 70 },
                lineHeight: 1.2,
              }}
            >
              Integrity
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#fff",

              fontSize: { xs: 20, lg: 36 },
            }}
          >
            Pacific Construction Group Ltd
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: -90,
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              p: { xs: "1rem", lg: "2rem" },
              boxShadow: "0px 5px 20px rgba(0,0,0,0.3)",
              alignItems: "center",
              flexDirection: { xs: "column", lg: "row" },
              backgroundImage: "linear-gradient(90deg,#bd0507 0,#840809 100%)",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{ color: "#fff", fontSize: { xs: 18, md: 28, lg: 32 } }}
              >
                Your dream home –
              </Typography>
              <Typography
                fontWeight={700}
                sx={{ color: "#fff", fontSize: { xs: 18, md: 28, lg: 32 } }}
              >
                We expert in it
              </Typography>
            </Box>
            <Button
              onClick={() => navigate("/freequote")}
              sx={{
                textTransform: "none",
                color: "#fff",
                border: "3px solid #fff",
                borderRadius: 15,
                px: { xs: 5, lg: 12 },
                py: { xs: 1, lg: 3 },
                ml: { xs: 0, lg: 3 },
                mr: { xs: 0, lg: 4 },
                mt: { xs: 2, lg: 0 },
                letterSpacing: 0.5,
                fontWeight: 200,
                ":hover": {
                  backgroundColor: "#fff",
                  color: "primary.main",
                },
              }}
            >
              Get a free quote today
            </Button>
          </Box>
        </Box>
      </Box>

      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", lg: "row" },
            mt: 15,
            mb: 10,
          }}
        >
          <Box sx={{ flex: 1.2, py: 4, px: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: 400, lineHeight: 1.5 }}>
              AT PCG HOMES, WE APPRECIATE THAT THE THOUGHT OF &nbsp;
              <span style={{ fontWeight: 700 }}>
                BUILDING A NEW HOME CAN BE OVERWHELMING
              </span>
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold" my={3}>
              But it doesn’t need to be.
            </Typography>
            <Typography variant="subtitle1" fontWeight={200}>
              As an expert we’re now with you to guaranty the course is followed
              in as much as possible stress-free method, and you enjoy a home
              you dreamt of.
            </Typography>
            <Typography variant="subtitle1" fontWeight={200} my={3}>
              We help Auckland families accomplish their dream of a class new
              home within a sensible budget; we confidently believe we’re
              building more than just a home, but a place for happiness for your
              family.
            </Typography>
          </Box>
          <Box sx={{ flex: 1, height: 600, width: "auto" }}>
            <img
              src={livingroom}
              alt="livingroom"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", flexDirection: { lg: "row", xs: "column" } }}
        >
          <Box sx={{ px: 3, flex: 1 }}>
            <Box>
              <Typography variant="h6" fontWeight={700}>
                Design and build
              </Typography>
              <Typography variant="subtitle1" fontWeight={200}>
                PCG homes have a wide variety of designer plans of high quality
                that you can customize to meet your exact needs.
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" fontWeight={700}>
                House and land packages
              </Typography>
              <Typography variant="subtitle1" fontWeight={200}>
                PCG homes have quality packages of homes and land for sale
                throughout New Zealand.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ px: 3, flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <Typography variant="h4" fontWeight={700}>
                Satisfaction&nbsp;
              </Typography>
              <Typography variant="h4" letterSpacing={1}>
                GUARANTEED
              </Typography>
            </Box>
            <Typography variant="body1" fontWeight={700} my={3}>
              Our extensive, nation-wide experience affords a unique and current
              perspective, which has proved invaluable to our clients in this
              rapidly evolving industry.
            </Typography>
            <Button
              sx={{
                borderRadius: 10,
                backgroundColor: "#fff",
                borderWidth: "2px",
                borderColor: "primary.main",
                textTransform: "none",
                my: 4,
                ":hover": {
                  borderWidth: "2px",
                  backgroundColor: "primary.main",
                  color: "#fff",
                },
              }}
              onClick={() => navigate("/services")}
              variant="outlined"
              size="large"
            >
              View All Services
            </Button>
          </Box>
        </Box>
      </Container>
      {/* Gallery Section */}
      <Box
        sx={{
          display: "flex",
          mt: 2,
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box sx={{ flex: 1 }}>
          <img
            src={proj1}
            alt="proj1"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Box>
        <Box sx={{ flex: 1.5 }}>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <img
                src={proj2}
                alt="proj2"
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                backgroundColor: "primary.main",
                display: "flex",
                py: { xs: 3, lg: 0 },
                px: { xs: 2, lg: 0 },
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" fontWeight={700} sx={{ color: "#fff" }}>
                Recents Projects
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#fff",
                  textAlign: "center",
                  fontWeight: 200,
                  my: 4,
                }}
              >
                Every projects comes with its own needs and challenges.Tell us
                your priorities,and we'll feel them to your satisfaction.
              </Typography>
              <Button
                sx={{
                  color: "#fff",
                  border: "2px solid #fff",
                  borderRadius: 10,
                  textTransform: "none",
                  px: 12,
                  py: 2,

                  ":hover": {
                    backgroundColor: "#fff",
                    color: "primary.main",
                  },
                }}
                onClick={() => navigate("/contact")}
                size="large"
              >
                Contact us
              </Button>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <img
              src={proj3}
              alt="proj2"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
        </Box>
      </Box>
      {/* Why Pcg homes */}
      <Container maxWidth="lg">
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            display: "flex",
            alignItems: "center",
            flexDirection: { lg: "row", xs: "column" },
            my: 10,
          }}
        >
          <Box sx={{ flex: 1, height: 500, width: "auto" }}>
            <img
              alt="diming"
              src={dining}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 35, md: 40, lg: 50 },
                pt: { xs: 2, lg: 0 },
              }}
            >
              Why <span style={{ fontWeight: 700 }}>PCG</span> homes?
            </Typography>

            <ul style={{ marginLeft: "4rem" }}>
              <li>
                <Typography variant="h5" fontWeight={100} my={3}>
                  Over 5 years in the business
                </Typography>
              </li>
              <li>
                <Typography variant="h5" fontWeight={100} my={3}>
                  Cost-effective solutions
                </Typography>
              </li>
              <li>
                <Typography variant="h5" fontWeight={100} my={3}>
                  Smart design and specs
                </Typography>
              </li>
              <li>
                <Typography variant="h5" fontWeight={100} my={3}>
                  Fully certified team and rock strong guarantees
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        {/* Pcg homes Family */}
        <Box
          sx={{
            backgroundColor: "primary.main",
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", lg: "row" },
            my: 10,
          }}
        >
          <Box sx={{ flex: 1, height: 500, width: "auto" }}>
            <img
              alt="homefamily"
              src={homefamily}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h2"
              fontWeight={700}
              sx={{ color: "#fff", mx: 4, textAlign: "center" }}
            >
              The PCG homes Family
            </Typography>
            <Button
              sx={{
                color: "#fff",
                border: "2px solid #fff",
                borderRadius: 10,
                textTransform: "none",
                px: 12,
                py: 2,
                my: 4,
                ":hover": {
                  backgroundColor: "#fff",
                  color: "primary.main",
                },
              }}
              size="large"
              onClick={() => navigate("/team")}
            >
              Meet the team
            </Button>
          </Box>
        </Box>
      </Container>
      {/* Contact Us  */}
      <Box
        sx={{
          backgroundImage: "linear-gradient(90deg,#bd0507 0,#840809 100%)",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", lg: "row" },
              py: 5,
            }}
          >
            <Box
              sx={{ flex: 1, display: "flex", alignItems: "flex-end", pb: 15 }}
            >
              <Box sx={{ height: 300, width: "auto" }}>
                <img
                  alt="contact"
                  src={contact}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1.5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box>
                <img src={whitelogo} alt="pcg_logo_white" />
              </Box>
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  color: "#fff",
                  my: 2,
                }}
              >
                Get a Free Quote Today!
              </Typography>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <label className="homelabel">Full Name</label>
                      <input
                        type="text"
                        className="homeinput"
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <label className="homelabel">Email</label>
                      <input
                        type="email"
                        className="homeinput"
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <label className="homelabel">Phone</label>
                      <input
                        type="text"
                        className="homeinput"
                        name="phone"
                        value={state.phone}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <label className="homelabel">
                        What Service do you need?
                      </label>

                      <select
                        id="service"
                        className="homeselect"
                        name="service"
                        value={state.service ? state.service : " "}
                        onChange={handleChange}
                      >
                        <option value=" Build for you" className="homeoption">
                          Build for you
                        </option>
                        <option
                          value=" Design and build"
                          className="homeoption"
                        >
                          Design and build
                        </option>
                        <option value=" other" className="homeoption">
                          other
                        </option>
                      </select>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ width: "100%" }}>
                      <label className="homelabel">Message</label>
                      <textarea
                        className="hometextarea"
                        rows="4"
                        name="message"
                        value={state.message}
                        onChange={handleChange}
                      ></textarea>
                    </Box>
                  </Grid>
                </Grid>
                <Button
                  onClick={handleClick}
                  sx={{
                    color: "#fff",
                    textTransform: "none",
                    border: "2px solid #fff",
                    px: 8,
                    py: 2,
                    borderRadius: 10,
                    my: 3,
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Home Testimonial */}
      <Box sx={{ my: 3, py: 3 }}>
        <Container maxWidth="lg">
          <Box sx={{}}>
            <Typography variant="h3" textAlign="center" sx={{ mb: 3 }}>
              Hear from our{" "}
              <span style={{ fontWeight: 700 }}>happy customers</span>
            </Typography>
            <Grid container spacing={3}>
              <Grid item sx={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    borderTopWidth: 5,
                    py: 2,
                    borderTopColor: "primary.main",
                    borderTopStyle: "solid",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "center", lg: "flex-start" },
                    }}
                  >
                    <FormatQuoteIcon sx={{ fontSize: "7rem", mt: -3, mx: 2 }} />
                  </Box>
                  <Box>
                    <Typography variant="body1" fontWeight={700} pb={2}>
                      Mahmoud Mahmoud
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200}>
                      We were looking to build a quite big and high-standard
                      house in Okura. We found Pacific construction through good
                      word of mouth as they built my friend's house. We spoke to
                      several other tradespeople for initial quotes but we found
                      Charles and his team are very friendly and patient. We
                      found the whole team very professional and experienced in
                      every conversation we had with them.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item sx={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    borderTopWidth: 5,
                    py: 2,
                    borderTopColor: "primary.main",
                    borderTopStyle: "solid",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "center", lg: "flex-start" },
                    }}
                  >
                    <FormatQuoteIcon sx={{ fontSize: "7rem", mt: -3, mx: 2 }} />
                  </Box>
                  <Box>
                    <Typography variant="body1" fontWeight={700} pb={2}>
                      Candy Zhao
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200}>
                      I wouldn’t hesitate to recommend Allen and the rest of the
                      team members. They provide exceptional project management
                      services, is always on time, and is very reliable. Allen
                      helped me create a modern stylish and functional building
                      plan. He also impressed me with the building works he
                      completed at my parent’s house. He provided practical
                      solutions to any problem and friendly service.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200} my={2}>
                      Thanks Allen
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <Button
                onClick={() => navigate("/testimonial")}
                variant="outlined"
                sx={{
                  borderColor: "primary.main",
                  backgroundColor: "#fff",
                  borderWidth: 2,
                  borderStyle: "solid",
                  color: "primary.main",
                  textTransform: "none",
                  px: 6,
                  py: 1,
                  borderRadius: 10,
                  ":hover": {
                    backgroundColor: "primary.main",
                    borderColor: "primary.main",
                    borderWidth: 2,
                    borderStyle: "solid",
                    color: "#fff",
                  },
                }}
              >
                Read More Feedback
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* our valuaed partners */}
      <Box sx={{ backgroundColor: "#f5f5f5" }}>
        <Container maxWidth="lg">
          <Typography variant="h2" textAlign="center" py={5}>
            Our valued&nbsp; <span style={{ fontWeight: 700 }}>partners</span>
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand1} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand2} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand3} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand4} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand5} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand6} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand7} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand8} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand9} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand10} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand11} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand12} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand13} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              container
              justifyContent="center"
              alignItems="center"
            >
              <img alt="brand1" src={brand14} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Modal open={modal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={{ backgroundColor: "#fff", py: 5, px: 5, borderRadius: 2 }}>
            <Typography
              variant="h5"
              color="green"
              textAlign="center"
              fontWeight={600}
            >
              Your Request For
            </Typography>
            <Typography
              variant="h5"
              color="green"
              textAlign="center"
              fontWeight={600}
            >
              Free Quote
            </Typography>
            <Typography
              variant="h5"
              color="green"
              textAlign="center"
              fontWeight={600}
            >
              Submitted Successfully !!
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => setModal(false)}
                sx={{
                  borderRadius: 10,
                  mt: 3,
                  px: 6,
                  py: 1,
                  backgroundColor: "green",
                  color: "#fff",
                  ":hover": {
                    backgroundColor: "green",
                    opacity: 0.8,
                  },
                }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Home;
