import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Home,
  Navbar,
  About,
  Contact,
  Resources,
  Services,
  Footer,
  Freequote,
  Testimonial,
  Privacypolicy,
  Blog,
  Team,
} from "./components";
import { COLORS } from "./constants/theme";
import Termscondition from "./components/common/Termscondition";

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="resources" element={<Resources />} />
          <Route path="services" element={<Services />} />
          <Route path="freequote" element={<Freequote />} />
          <Route path="testimonial" element={<Testimonial />} />
          <Route path="terms" element={<Termscondition />} />
          <Route path="privacy" element={<Privacypolicy />} />
          <Route path="blog" element={<Blog />} />
          <Route path="team" element={<Team />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
