import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  drink,
  manwalking,
  Plant,
  womancoffe,
} from "../../constants/ImagePath";

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${Plant})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "70vh",
          backgroundAttachment: "fixed",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5, mt: 3 }}
            variant="h3"
          >
            Blog
          </Typography>
        </Box>
      </Box>
      <Box sx={{ py: 10 }}>
        <Container maxWidht="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Box
                sx={{
                  bacgroundColor: "#fff",
                  flex: 1,
                  boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
                }}
              >
                <Box sx={{ height: 200, width: "100%" }}>
                  <img
                    alt="card1"
                    src={drink}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box sx={{ px: 2 }}>
                  <Typography variant="body1" fontWeight={700} mt={2}>
                    Make the most of the season by following these simple
                    guidelines
                  </Typography>
                  <Typography variant="subtitle1" fontWeight={200} py={2}>
                    The new season is a great reason to make and keep
                    resolutions. Whether it’s eating or cleaning out the garage,
                    here are some tips for making and keeping resolutions.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                sx={{
                  bacgroundColor: "#fff",
                  flex: 1,
                  boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
                }}
              >
                <Box sx={{ height: 200, width: "100%" }}>
                  <img
                    alt="card1"
                    src={manwalking}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box sx={{ px: 2 }}>
                  <Typography variant="body1" fontWeight={700} mt={2}>
                    Keep in touch with site visitors and boost loyalty
                  </Typography>
                  <Typography variant="subtitle1" fontWeight={200} py={2}>
                    There are so many good reasons to communicate with site
                    visitors. Tell them about sales and new products or update
                    them with tips and information.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                sx={{
                  flex: 1,
                  bacgroundColor: "#fff",
                  boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
                }}
              >
                <Box sx={{ height: 200, width: "100%" }}>
                  <img
                    alt="card1"
                    src={womancoffe}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box sx={{ px: 2 }}>
                  <Typography variant="body1" fontWeight={700} mt={2}>
                    Tips for writing great posts that increase your site traffic
                  </Typography>
                  <Typography variant="subtitle1" fontWeight={200} py={2}>
                    Write about something you know. If you don’t know much about
                    a specific topic that will interest your readers, invite an
                    expert to write about it.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Blog;
