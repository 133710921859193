import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Plant } from "../../constants/ImagePath";

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${Plant})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "70vh",
          backgroundAttachment: "fixed",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5, mt: 3 }}
            variant="h3"
          >
            Team
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="lg" sx={{ my: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "primary.main",
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#fff",
                  fontWeight: 700,
                  textAlign: "center",
                  py: { xs: 4, lg: 0 },
                }}
              >
                The PCG homes Family
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Charles Chen
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Senior Project Manager
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Allen Wang
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Project manager
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Charlotte
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Admin & Marketing
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Leo Ni
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Specialist adviser
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Vinky Huang
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Quantity surveying
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Team;
