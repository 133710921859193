import { Button, Container, Grid, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import { contact, contactworkers, whitelogo } from "../../constants/ImagePath";

const Freequote = () => {
  const [state, setstate] = useState({});
  const [modal, setModal] = useState(false);
  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };
  const handleClick = () => {
    setstate({
      name: "",
      phone: "",
      email: "",
      service: "",
      message: "",
    });
    setModal(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${contactworkers})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: { xs: "40vh", md: "50vh", lg: "60vh" },
          backgroundAttachment: "scroll",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5 }}
            variant="h3"
          >
            Quote
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundImage: "linear-gradient(90deg,#bd0507 0,#840809 100%)",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", lg: "row" },
              py: 5,
            }}
          >
            <Box
              sx={{ flex: 1, display: "flex", alignItems: "flex-end", pb: 15 }}
            >
              <Box sx={{ height: 300, width: "auto" }}>
                <img
                  alt="contact"
                  src={contact}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1.5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box>
                <img src={whitelogo} alt="pcg_logo_white" />
              </Box>
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  color: "#fff",
                  my: 2,
                }}
              >
                Get a Free Quote Today!
              </Typography>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <label className="homelabel">Full Name</label>
                      <input
                        type="text"
                        className="homeinput"
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <label className="homelabel">Email</label>
                      <input
                        type="email"
                        className="homeinput"
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <label className="homelabel">Phone</label>
                      <input
                        type="text"
                        className="homeinput"
                        name="phone"
                        value={state.phone}
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <label className="homelabel">
                        What Service do you need?
                      </label>

                      <select
                        id="service"
                        className="homeselect"
                        name="service"
                        value={state.service ? state.service : " "}
                        onChange={handleChange}
                      >
                        <option value=" Build for you" className="homeoption">
                          Build for you
                        </option>
                        <option
                          value=" Design and build"
                          className="homeoption"
                        >
                          Design and build
                        </option>
                        <option value=" other" className="homeoption">
                          other
                        </option>
                      </select>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ width: "100%" }}>
                      <label className="homelabel">Message</label>
                      <textarea
                        className="hometextarea"
                        rows="4"
                        name="message"
                        value={state.message}
                        onChange={handleChange}
                      ></textarea>
                    </Box>
                  </Grid>
                </Grid>
                <Button
                  onClick={handleClick}
                  sx={{
                    color: "#fff",
                    textTransform: "none",
                    border: "2px solid #fff",
                    px: 8,
                    py: 2,
                    borderRadius: 10,
                    my: 3,
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Modal open={modal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={{ backgroundColor: "#fff", py: 5, px: 5, borderRadius: 2 }}>
            <Typography
              variant="h5"
              color="green"
              textAlign="center"
              fontWeight={600}
            >
              Your Request For
            </Typography>
            <Typography
              variant="h5"
              color="green"
              textAlign="center"
              fontWeight={600}
            >
              Free Quote
            </Typography>
            <Typography
              variant="h5"
              color="green"
              textAlign="center"
              fontWeight={600}
            >
              Submitted Successfully !!
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => setModal(false)}
                sx={{
                  borderRadius: 10,
                  mt: 3,
                  px: 6,
                  py: 1,
                  backgroundColor: "green",
                  color: "#fff",
                  ":hover": {
                    backgroundColor: "green",
                    opacity: 0.8,
                  },
                }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Freequote;
