import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  abouthead,
  aboutwhy,
  proj1,
  proj2,
  proj3,
} from "../../constants/ImagePath";
import "./about.css";
const About = () => {
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${abouthead})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "70vh",
          backgroundAttachment: "fixed",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5, mt: 3 }}
            variant="h3"
          >
            About Us
          </Typography>
        </Box>
      </Box>

      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",

            alignItems: "center",
            flexDirection: { xs: "column", lg: "row" },
            my: 10,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <img
              alt="aboutwhy"
              src={aboutwhy}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              pl: { lg: 4, xs: 0 },
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                mb: 4,
                fontSize: { xs: 30, md: 35, lg: 40 },
              }}
            >
              Why Choose Us
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" fontWeight={700}>
                Over 5 years in the business
              </Typography>
              <Typography>
                We've been serving Aucklanders build their dream homes for over
                5 years, with excellence in a reasonable budget.
              </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" fontWeight={700}>
                Cost-effective solutions
              </Typography>
              <Typography>
                We control our overheads and buy in bulk so you enjoy the most
                cost effective pricing available in the marketplace.
              </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" fontWeight={700}>
                Smart design and specs
              </Typography>
              <Typography>
                We use modern design techniques to deliver the best in
                liveability while keeping costs down.
              </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" fontWeight={700}>
                Fully certified team and rock strong guarantees
              </Typography>
              <Typography>
                Our team is fully certified and our work comes backed with
                substantial assurances.
              </Typography>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Button
                sx={{
                  borderRadius: 10,
                  backgroundColor: "#fff",
                  borderWidth: "2px",
                  borderColor: "primary.main",
                  textTransform: "none",

                  ":hover": {
                    borderWidth: "2px",
                    backgroundColor: "primary.main",
                    color: "#fff",
                  },
                }}
                variant="outlined"
                size="large"
                onClick={() => navigate("/contact")}
              >
                Contact us
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>

      <Box className="aboutcutomer">
        <Box
          sx={{
            zIndex: 2,
            position: "absolute",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            px: 2,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography variant="h3" sx={{ color: "#fff" }} fontWeight={700}>
            Our Customers
          </Typography>
          <Typography sx={{ color: "#fff", my: 2 }}>
            We are extremely honoured of our rock-solid customer relationships
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            – you can read about their experiences here.
          </Typography>
        </Box>
      </Box>

      {/* gallery sec */}
      <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}>
        <Box sx={{ flex: 1 }}>
          <img
            src={proj1}
            alt="proj1"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Box>
        <Box sx={{ flex: 1.5 }}>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <img
                src={proj2}
                alt="proj2"
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                backgroundColor: "primary.main",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                py: { xs: 3, lg: 0 },
                px: { xs: 2, lg: 0 },
              }}
            >
              <Typography variant="h4" fontWeight={700} sx={{ color: "#fff" }}>
                Recents Projects
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#fff",
                  textAlign: "center",
                  fontWeight: 200,
                  my: 4,
                }}
              >
                Every projects comes with its own needs and challenges.Tell us
                your priorities,and we'll feel them to your satisfaction.
              </Typography>
              <Button
                sx={{
                  color: "#fff",
                  border: "2px solid #fff",
                  borderRadius: 10,
                  textTransform: "none",
                  px: 12,
                  py: 2,

                  ":hover": {
                    backgroundColor: "#fff",
                    color: "primary.main",
                  },
                }}
                onClick={() => navigate("/contact")}
                size="large"
              >
                Contact us
              </Button>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <img
              src={proj3}
              alt="proj2"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
        </Box>
      </Box>
      {/*  Team seciton */}
      <Container maxWidth="lg" sx={{ my: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "primary.main",
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#fff",
                  fontWeight: 700,
                  textAlign: "center",
                  py: { xs: 4, lg: 0 },
                }}
              >
                The PCG homes Family
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Charles Chen
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Senior Project Manager
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Allen Wang
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Project manager
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Charlotte
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Admin & Marketing
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Leo Ni
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Specialist adviser
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: 2,
                borderBottomWidth: 8,
                borderBottomColor: "primary.main",
                borderBottomStyle: "solid",
                boxShadow: "0 3px 15px rgba(0,0,0,0.3)",
              }}
            >
              <Typography
                variant="h5"
                fontWeight={700}
                textAlign="center"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: "primary.main",
                  borderBottomStyle: "solid",
                  py: 3,
                }}
              >
                Vinky Huang
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "primary.main",
                  fontWeight: 200,
                  mb: 2,
                  mt: 3,
                }}
              >
                Quantity surveying
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* Join team sec */}
      <Box className="jointeam">
        <Box
          sx={{
            zIndex: 2,
            mt: -12,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{ color: "#fff", fontSize: { xs: 25, lg: 40 } }}
            fontWeight={700}
          >
            Want to join the team?
          </Typography>
          <Button
            sx={{
              color: "#fff",
              backgroundColor: "transparent",
              border: "2px solid #fff",
              borderRadius: 10,
              px: 10,
              py: 2,
              my: 5,
              ":hover": {
                backgroundColor: "primary.main",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "primary.main",
              },
            }}
            onClick={() => navigate("/contact")}
          >
            Work with us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
