import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";

import {
  g1,
  g10,
  g11,
  g12,
  g2,
  g3,
  g4,
  g5,
  g6,
  g7,
  g8,
  g9,
  house1,
  resto,
  services1,
} from "../../constants/ImagePath";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${services1})`,
          backgroundPosition: { xs: "top", lg: "center" },
          backgroundSize: "cover",
          height: { xs: "40vh", md: "50vh", lg: "60vh" },

          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5, mt: 3 }}
            variant="h2"
          >
            Services
          </Typography>
        </Box>
      </Box>

      <Box sx={{ backgroundColor: "#f5f5f5", py: 8 }}>
        <Container maxWidth="xl">
          <Typography
            textAlign="center"
            sx={{ fontSize: { xs: 22, md: 28, lg: 35 } }}
          >
            <span style={{ fontWeight: 700 }}>PCG Homes</span> makes Subdivide
            easy
          </Typography>
          <Typography textAlign="center" sx={{ fontWeight: 200, my: 2 }}>
            Contact us today and make the most of your land
          </Typography>
          <Typography textAlign="center" variant="subtitle1">
            The Auckland Unitary Plan has created an opportunity for property
            developers. Now you can now build seven or eight terrace houses on
            the same site where you could previously build only two or three. To
            benefit you to make the most of the changes, we’ve streamlined the
            subdivision process.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{
                color: "primary.main",
                borderWidth: 2,
                my: 8,
                borderStyle: "solid",
                borderColor: "primary.main",
                borderRadius: 10,
                textTransform: "none",
                backgroundColor: "transparent",
                px: 12,
                py: 2,

                ":hover": {
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: "primary.main",
                  color: "#fff",
                  backgroundColor: "primary.main",
                },
              }}
              size="large"
            >
              Contact us
            </Button>
          </Box>
        </Container>
      </Box>
      <Box>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ px: { lg: 15, xs: 2 }, width: "100%" }}>
              <Box
                sx={{
                  borderTopWidth: 4,
                  borderTopColor: "primary.main",
                  borderTopStyle: "solid",
                  width: 100,
                  mb: 5,
                  mt: { xs: 2, lg: 1 },
                }}
              ></Box>

              <Typography fontWeight={700}>Build for you</Typography>
              <Typography variant="subtitle1" my={2}>
                At PCG Homes, we believe your building experience should be an
                exciting journey ending with a new home you’ll be happy in.
              </Typography>
              <Button
                sx={{
                  color: "primary.main",
                  borderWidth: 2,
                  my: 8,
                  borderStyle: "solid",
                  borderColor: "primary.main",
                  borderRadius: 10,
                  textTransform: "none",
                  backgroundColor: "transparent",
                  px: 8,
                  py: 2,

                  ":hover": {
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: "primary.main",
                    color: "#fff",
                    backgroundColor: "primary.main",
                  },
                }}
                size="large"
              >
                Get a free quote
              </Button>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <img
              src={house1}
              alt="house1"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", lg: "row" },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <img
              src={resto}
              alt="resto"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Box>
          <Box
            sx={{
              backgroundColor: "primary.main",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ px: { xs: 2, lg: 15 }, py: 5, width: "100%" }}>
              <Box
                sx={{
                  borderTopWidth: 4,
                  borderTopColor: "#fff",
                  borderTopStyle: "solid",
                  width: 100,
                  mb: 5,
                }}
              ></Box>

              <Typography fontWeight={700} sx={{ color: "#fff" }}>
                Design and build
              </Typography>
              <Typography variant="subtitle1" my={2} sx={{ color: "#fff" }}>
                Allowing PCG Homes look after the design and build process
                removes the stress out of your new home journey.
              </Typography>
              <Button
                sx={{
                  color: "#fff",
                  borderWidth: 2,
                  my: 8,
                  border: "2px solid #fff",
                  borderRadius: 10,
                  textTransform: "none",
                  backgroundColor: "transparent",
                  px: 8,
                  py: 2,

                  ":hover": {
                    color: "primary.main",
                    backgroundColor: "#fff",
                  },
                }}
                size="large"
              >
                Get a free quote
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ py: 10 }}>
        <Container maxWidth="lg">
          <Typography variant="h3" fontWeight={700} textAlign="center">
            Projects
          </Typography>
          <Typography variant="subtitle1" py={4} textAlign="center">
            In addition to residential services, we have many commercial
            clients, and handle <br /> installation & maintenance for a range of
            projects and environments.
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g1}
                alt="g1"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g2}
                alt="g2"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g3}
                alt="g3"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g4}
                alt="g4"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g5}
                alt="g5"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g6}
                alt="g6"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g7}
                alt="g7"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g8}
                alt="g8"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g9}
                alt="g9"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g10}
                alt="g10"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g11}
                alt="g11"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4} item>
              <img
                src={g12}
                alt="g12"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Services;
