import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { contactworkers } from "../../constants/ImagePath";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Button, Container, Grid, Modal, Typography } from "@mui/material";
import "./contact.css";
const Contact = () => {
  const [state, setstate] = useState({});
  const [modal, setModal] = useState(false);
  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setstate({
      name: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    });
    setModal(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${contactworkers})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "70vh",
          backgroundAttachment: "fixed",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5, mt: 3 }}
            variant="h3"
            textAlign="center"
          >
            Contact Us
          </Typography>
          <Typography
            sx={{ color: "#fff", fontWeight: 300, ml: 5, mt: 2 }}
            variant="h6"
            textAlign="center"
          >
            Let us help you make your dream home into a reality.
          </Typography>
        </Box>
      </Box>

      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            py: 10,
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box sx={{ pl: { lg: 6, xs: 2 } }}>
              <Typography variant="h3" sx={{ mb: 4, fontWeight: "700" }}>
                PCG contacts
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 200, my: 1 }}>
                86G Bush Road
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 200, my: 1 }}>
                Auckland 0632
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 200, my: 1 }}>
                New Zealand
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 200, my: 1 }}>
                022-595-8575
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 200, my: 1 }}>
                Info@nzpcg.co.nz
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  mt: 4,
                }}
              >
                <Box
                  sx={{
                    padding: " 3px 15px",
                    borderRadius: 8,
                    color: "#fff",
                    fontWeight: 800,
                    mr: 1,
                    backgroundImage:
                      "linear-gradient(90deg, #bd0507 0,#840809 100%)",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    f
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 8,
                    color: "#fff",
                    fontWeight: 800,
                    marginRight: 1,
                    backgroundImage:
                      "linear-gradient(90deg, #bd0507 0,#840809 100%)",
                  }}
                >
                  <InstagramIcon sx={{ color: "#fff", mx: 1 }} />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 8,
                    color: "#fff",
                    fontWeight: 800,
                    marginRight: 1,
                    backgroundImage:
                      "linear-gradient(90deg, #bd0507 0,#840809 100%)",
                  }}
                >
                  <TwitterIcon sx={{ color: "#fff", mx: 1 }} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 1, mt: { xs: 3 } }}>
            <Box
              sx={{
                heigh: "100%",
                widthP: "100%",
              }}
            >
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3197.2806290407916!2d174.70156541502297!3d-36.7398345811265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d3be58992720f%3A0xcb97b48badcef891!2s86G%20Bush%20Road%2C%20Albany%2C%20Auckland%200632%2C%20New%20Zealand!5e0!3m2!1sen!2sin!4v1639560261124!5m2!1sen!2sin"
                width={"100%"}
                height={"400"}
                style={{ border: 0 }}
                allowfullscreen
                loading="lazy"
              ></iframe>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box sx={{ backgroundColor: "rgba(0,0,0,0.06)" }}>
        <Container maxWidth="lg">
          <Box sx={{ py: 5 }}>
            <Typography variant="h4" fontWeight={700} textAlign="center">
              Get in touch
            </Typography>
            <Typography
              variant="body1"
              fontWeight={200}
              textAlign="center"
              py={3}
            >
              Simply fill in the form below, or contact us <br />
              via email or phone, and we’ll help you get started.
            </Typography>

            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <label className="contactlabel">Full Name</label>
                    <input
                      type="text"
                      className="contactinput"
                      name="name"
                      value={state.name}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <label className="contactlabel">Email</label>
                    <input
                      type="email"
                      className="contactinput"
                      name="email"
                      value={state.email}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <label className="contactlabel">Phone</label>
                    <input
                      type="text"
                      className="contactinput"
                      name="phone"
                      value={state.phone}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <label className="contactlabel">Subject</label>
                    <input
                      type="text"
                      className="contactinput"
                      name="subject"
                      value={state.subject}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ width: "100%" }}>
                    <label className="contactlabel">Message</label>
                    <textarea
                      className="contacttextarea"
                      rows="4"
                      name="message"
                      value={state.message}
                      onChange={handleChange}
                    ></textarea>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleSubmit}
                  sx={{
                    color: "primary.main",
                    backgroundColor: "transparent",
                    textTransform: "none",
                    borderStyle: "solid",
                    borderColor: "primary.main",
                    borderWidth: 2,
                    px: 8,
                    py: 2,
                    borderRadius: 10,
                    my: 3,
                    ":hover": {
                      backgroundColor: "primary.main",
                      borderStyle: "solid",
                      borderColor: "primary.main",
                      borderWidth: 2,
                      color: "#fff",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Modal open={modal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={{ backgroundColor: "#fff", py: 5, px: 5, borderRadius: 2 }}>
            <Typography
              variant="h5"
              color="green"
              textAlign="center"
              fontWeight={600}
            >
              Your Request Form
            </Typography>

            <Typography
              variant="h5"
              color="green"
              textAlign="center"
              fontWeight={600}
            >
              Submitted Successfully !!
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => setModal(false)}
                sx={{
                  borderRadius: 10,
                  mt: 3,
                  px: 6,
                  py: 1,
                  backgroundColor: "green",
                  color: "#fff",
                  ":hover": {
                    backgroundColor: "green",
                    opacity: 0.8,
                  },
                }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Contact;
