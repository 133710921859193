import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { logo1 } from "../../constants/ImagePath";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  let navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#000",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-evenly",
          alignItems: { xs: "flex-start", lg: "center" },
          py: "3rem",
          px: { xs: 3, lg: 0 },
        }}
      >
        <Box sx={{ my: { xs: 3, lg: 0 } }}>
          <img
            src={logo1}
            alt="pcg_logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        </Box>
        <Box sx={{ my: { xs: 3, lg: 0 } }}>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#fff",
              borderBottom: "1px solid #fff",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            Home
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#fff",
              borderBottom: "1px solid #fff",
              cursor: "pointer",
            }}
            onClick={() => navigate("/about")}
          >
            About us
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#fff",
              borderBottom: "1px solid #fff",
              cursor: "pointer",
            }}
            onClick={() => navigate("/services")}
          >
            Services We Offer
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#fff",
              borderBottom: "1px solid #fff",
              cursor: "pointer",
            }}
            onClick={() => navigate("/resources")}
          >
            Resources
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#fff",
              borderBottom: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            Faqs
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#fff",
              borderBottom: "1px solid #fff",
              cursor: "pointer",
            }}
            onClick={() => navigate("/contact")}
          >
            contact us
          </Typography>
        </Box>
        <Box sx={{ my: { xs: 3, lg: 0 } }}>
          <Typography variant="h6" sx={{ mb: 2, color: "#fff" }}>
            Main Office
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#fff", fontWeight: 300 }}
          >
            86G Bush Road
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#fff", fontWeight: 300 }}
          >
            Auckland 0632
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#fff", fontWeight: 300 }}
          >
            New Zealand
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mt: 1,
            }}
          >
            <Box
              sx={{
                padding: " 3px 15px",
                borderRadius: 8,
                color: "#fff",
                fontWeight: 800,
                mr: 1,
                cursor: "pointer",
                backgroundImage:
                  "linear-gradient(90deg, #bd0507 0,#840809 100%)",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                f
              </Typography>
            </Box>
            <Box
              sx={{
                padding: " 3px 10px",
                borderRadius: 8,
                color: "#fff",
                fontWeight: 800,
                marginRight: 1,
                cursor: "pointer",
                backgroundImage:
                  "linear-gradient(90deg, #bd0507 0,#840809 100%)",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                in
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                color: "#fff",
                fontWeight: 800,
                marginRight: 1,
                cursor: "pointer",
                backgroundImage:
                  "linear-gradient(90deg, #bd0507 0,#840809 100%)",
              }}
            >
              <InstagramIcon sx={{ color: "#fff", mx: 1 }} />
            </Box>
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                color: "#fff",
                fontWeight: 800,
                marginRight: 1,
                backgroundImage:
                  "linear-gradient(90deg, #bd0507 0,#840809 100%)",
              }}
            >
              <MailOutlineIcon sx={{ color: "#fff", mx: 1 }} />
            </Box>
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                color: "#fff",
                fontWeight: 800,
                marginRight: 1,
                backgroundImage:
                  "linear-gradient(90deg, #bd0507 0,#840809 100%)",
              }}
            >
              <TwitterIcon sx={{ color: "#fff", mx: 1 }} />
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="h6" sx={{ mb: 2, color: "#fff" }}>
            Main Office
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#fff", fontWeight: 300 }}
          >
            022-595-85755
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#fff", fontWeight: 300 }}
          >
            Info@nzpcg.co.nz
          </Typography>
          <Button
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              borderWidth: "2px",
              mt: 1,
              textTransform: "none",
              borderRadius: 6,
              ":hover": {
                backgroundColor: "primary.main",
                borderWidth: "2px",
              },
            }}
            onClick={() => navigate("/freequote")}
            variant="outlined"
            size="large"
          >
            Get a free quote
          </Button>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#0D0D0D", py: 2, px: 2 }}>
        <Typography
          sx={{
            textAlign: "center",
            color: "#fff",
            mx: 2,
            fontSize: { xs: 14 },
          }}
        >
          2021 Copyrights pcghomes.co.nz -{" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => navigate("/privacy")}
          >
            Privacy Policy
          </span>{" "}
          -{" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => navigate("/terms")}
          >
            Terms and Conditions
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
