import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { logo2 } from "../../constants/ImagePath";
import { useNavigate, useLocation } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import { COLORS } from "../../constants/theme";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import InstagramIcon from "@mui/icons-material/Instagram";
const Navbar = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [scrollNav, setScrollNav] = useState(false);
  const [visible, setVisible] = useState(false);

  const Closedrawer = (route) => {
    setVisible(!visible);
    navigate(route);
  };
  const handleScroll = (e) => {
    if (window.pageYOffset > 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const style1 = {
    height: "50px",
    transition: "height 0.3s",
    cursor: "pointer",
  };
  const style2 = {
    height: "60px",
    transition: "height 0.3s",
    cursor: "pointer",
  };
  return (
    <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 2 }}>
      <Paper
        component={Box}
        sx={{
          padding: ".3rem 1rem",
          borderRadius: "none",
        }}
        elevation={scrollNav ? 3 : 0}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <MenuIcon
              sx={{ color: "primary.main", fontSize: "2rem" }}
              onClick={() => setVisible(!visible)}
            />
          </Box>
          <div>
            <img
              src={logo2}
              alt="pcg-logo1"
              style={scrollNav ? style1 : style2}
              onClick={() => navigate("/")}
            />
          </div>

          <Box sx={{ display: { xs: "none", lg: "flex" } }}>
            <Button
              onClick={() => navigate("/")}
              sx={{
                color: location.pathname === "/" ? "primary.main" : "#000",
                typography: "body1",
                fontWeight: "bold",
                ":hover": { color: "primary.main" },
              }}
            >
              Home
            </Button>
            <Button
              onClick={() => navigate("about")}
              sx={{
                color: location.pathname === "/about" ? "primary.main" : "#000",
                typography: "body1",
                fontWeight: "bold",
                ":hover": { color: "primary.main" },
              }}
            >
              About us
            </Button>
            <Button
              onClick={() => navigate("services")}
              sx={{
                color:
                  location.pathname === "/services" ? "primary.main" : "#000",
                typography: "body1",
                fontWeight: "bold",
                ":hover": { color: "primary.main" },
              }}
            >
              Services We offer
            </Button>
            <Button
              onClick={() => navigate("resources")}
              sx={{
                color:
                  location.pathname === "/resources" ? "primary.main" : "#000",
                typography: "body1",
                fontWeight: "bold",
                ":hover": { color: "primary.main" },
              }}
            >
              Resources
            </Button>
            <Button
              onClick={() => navigate("contact")}
              sx={{
                color:
                  location.pathname === "/contact" ? "primary.main" : "#000",
                typography: "body1",
                fontWeight: "bold",
                ":hover": { color: "primary.main" },
              }}
            >
              Contact us
            </Button>
          </Box>
          <a href="tel: 0225958575" className="number">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <CallIcon
                sx={{
                  color: COLORS.primary,
                  fontSize: "1.5rem",
                  mr: { xs: 0, lg: 1.5 },
                  ml: { xs: 1, lg: 0 },
                }}
              />
              <Typography
                sx={{
                  display: { xs: "none", lg: "block" },
                  fontWeight: "bold",
                  ":hover": {
                    color: "primary.main",
                  },
                }}
                variant="h5"
              >
                022-595-8575
              </Typography>
            </Box>
          </a>
        </Box>
      </Paper>
      <Box sx={{ position: "fixed", bottom: 60, right: 40 }}>
        <Button
          sx={{
            color: "#fff",
            textTransform: "none",
            py: 1.5,
            px: 4,
            backgroundImage: "linear-gradient(90deg,#bd0507 0,#840809 100%)",
            borderRadius: 10,
          }}
          onClick={() => navigate("/freequote")}
        >
          Get a free quote
        </Button>
      </Box>
      <Drawer open={visible} onClose={() => setVisible(!visible)}>
        <Box
          sx={{
            backgroundColor: "primary.main",
            flex: 1,
            height: "100vh",
            width: 250,
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              px: 3,
              py: 5,
            }}
          >
            <CloseIcon
              sx={{ color: "#fff", fontSize: "1.5rem" }}
              onClick={Closedrawer}
            />
          </Box>
          <List>
            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/")}>
                <ListItemText
                  primary="Home"
                  sx={{
                    color: "#fff",
                  }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/about")}>
                <ListItemText primary="About Us" sx={{ color: "#fff" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/services")}>
                <ListItemText
                  primary="Services We Offer"
                  sx={{ color: "#fff" }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/blog")}>
                <ListItemText primary="Blog" sx={{ color: "#fff" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/contact")}>
                <ListItemText primary="Contact Us" sx={{ color: "#fff" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/resources")}>
                <ListItemText primary="Faqs" sx={{ color: "#fff" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/testimonial")}>
                <ListItemText primary="Testimonials" sx={{ color: "#fff" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/team")}>
                <ListItemText primary="team" sx={{ color: "#fff" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/privacy")}>
                <ListItemText primary="Privacy Policy" sx={{ color: "#fff" }} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemButton onClick={() => Closedrawer("/terms")}>
                <ListItemText
                  primary="Term and Conditions"
                  sx={{ color: "#fff" }}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Button
            onClick={() => Closedrawer("/freequote")}
            sx={{
              bacgroundColor: "transparent",
              textTransform: "none",
              border: "2px solid #fff",
              ml: 4,
              mt: 2,
              py: 1,
              px: 4,
              color: "#fff",
              borderRadius: 10,
              ":hover": {
                bacgroundColor: "#fff",
                color: "primary",
              },
            }}
          >
            Get a free quote
          </Button>
          <Box sx={{ display: "flex", px: 3, my: 2 }}>
            <Box
              sx={{
                padding: " 3px 15px",
                borderRadius: 8,
                color: "blue",
                fontWeight: 800,
                mr: 1,
                backgroundColor: "#fff",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                f
              </Typography>
            </Box>
            <Box
              sx={{
                padding: " 3px 10px",
                borderRadius: 8,
                color: "blue",
                fontWeight: 800,
                marginRight: 1,
                backgroundColor: "#fff",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                in
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 8,
                color: "blue",
                fontWeight: 800,
                marginRight: 1,
                backgroundColor: "#fff",
              }}
            >
              <InstagramIcon sx={{ color: "blue", mx: 1 }} />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Navbar;
