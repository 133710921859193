import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

import { Plant } from "../../constants/ImagePath";
const Testimonial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${Plant})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "70vh",
          backgroundAttachment: "fixed",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5, mt: 3 }}
            variant="h3"
          >
            Testimonials
          </Typography>
        </Box>
      </Box>

      <Box sx={{ my: 3, py: 10 }}>
        <Container maxWidth="lg">
          <Box sx={{}}>
            <Typography variant="h3" textAlign="center" sx={{ mb: 3 }}>
              Hear from our{" "}
              <span style={{ fontWeight: 700 }}>happy customers</span>
            </Typography>
            <Grid container spacing={3}>
              <Grid item sx={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    borderTopWidth: 5,
                    py: 2,
                    borderTopColor: "primary.main",
                    borderTopStyle: "solid",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "center", lg: "flex-start" },
                    }}
                  >
                    <FormatQuoteIcon sx={{ fontSize: "7rem", mt: -3, mx: 2 }} />
                  </Box>
                  <Box>
                    <Typography variant="body1" fontWeight={700} pb={2}>
                      Mahmoud Mahmoud
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200}>
                      We were looking to build a quite big and high-standard
                      house in Okura. We found Pacific construction through good
                      word of mouth as they built my friend's house. We spoke to
                      several other tradespeople for initial quotes but we found
                      Charles and his team are very friendly and patient. We
                      found the whole team very professional and experienced in
                      every conversation we had with them.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item sx={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    borderTopWidth: 5,
                    py: 2,
                    borderTopColor: "primary.main",
                    borderTopStyle: "solid",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "center", lg: "flex-start" },
                    }}
                  >
                    <FormatQuoteIcon sx={{ fontSize: "7rem", mt: -3, mx: 2 }} />
                  </Box>
                  <Box>
                    <Typography variant="body1" fontWeight={700} pb={2}>
                      Candy Zhao
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200}>
                      I wouldn’t hesitate to recommend Allen and the rest of the
                      team members. They provide exceptional project management
                      services, is always on time, and is very reliable. Allen
                      helped me create a modern stylish and functional building
                      plan. He also impressed me with the building works he
                      completed at my parent’s house. He provided practical
                      solutions to any problem and friendly service.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200} my={2}>
                      Thanks Allen
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item sx={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    borderTopWidth: 5,
                    py: 2,
                    borderTopColor: "primary.main",
                    borderTopStyle: "solid",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "center", lg: "flex-start" },
                    }}
                  >
                    <FormatQuoteIcon sx={{ fontSize: "7rem", mt: -3, mx: 2 }} />
                  </Box>
                  <Box>
                    <Typography variant="body1" fontWeight={700} pb={2}>
                      Dian Zheng
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200}>
                      I wouldn’t hesitate to recommend Allen and the rest of the
                      team members.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200} my={2}>
                      They provide exceptional project management services, is
                      always on time, and is very reliable. Allen helped me
                      create a modern stylish and functional building plan. He
                      also impressed me with the building works he completed at
                      my parent’s house. He provided practical solutions to any
                      problem and friendly service.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200}>
                      Thanks Allen
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item sx={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    borderTopWidth: 5,
                    py: 2,
                    borderTopColor: "primary.main",
                    borderTopStyle: "solid",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "center", lg: "flex-start" },
                    }}
                  >
                    <FormatQuoteIcon sx={{ fontSize: "7rem", mt: -3, mx: 2 }} />
                  </Box>
                  <Box>
                    <Typography variant="body1" fontWeight={700} pb={2}>
                      James Scheib
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200}>
                      Evan managed the renovation of our kitchen, laundry,
                      living room, and down-stair outdoor deck. He also built an
                      amazing extra outdoor area opposite our existing swimming
                      pool. We were renovating for the first time. We found
                      Evan’s suggestions reflected his expertise and knowledge
                      and his flexibility proved very beneficial to us.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200} my={2}>
                      This enhanced the overall experience and outcome.
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={200}>
                      We were very happy with the pacific construction group and
                      highly recommend them.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Testimonial;
