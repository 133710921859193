import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Plant } from "../../constants/ImagePath";

const Termscondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Box
        className="backimage1"
        sx={{
          backgroundImage: `url(${Plant})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "70vh",
          backgroundAttachment: "fixed",
          zIndex: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: 700, ml: 5, mt: 3 }}
            variant="h3"
          >
            Terms and Conditions
          </Typography>
        </Box>
      </Box>
      <Box sx={{ py: 10 }}>
        <Container maxWidth="lg">
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            Below are the Terms and Conditions for use of pcghomes.co.nz. Please
            read these carefully. If you need to contact us regarding any aspect
            of the following terms of use of our website, please contact us on
            the following email address – info@pcghomes.co.nz.
          </Typography>
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            By accessing the content of pcghomes.co.nz ( hereafter referred to
            as website ) you agree to the terms and conditions set out herein
            and also accept our Privacy Policy. If you do not agree to any of
            the terms and conditions you should not continue to use the Website
            and leave immediately.
          </Typography>
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            You agree that you shall not use the website for any illegal
            purposes, and that you will respect all applicable laws and
            regulations.
          </Typography>
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            You agree not to use the website in a way that may impair the
            performance, corrupt or manipulate the content or information
            available on the website or reduce the overall functionality of the
            website.
          </Typography>
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            You agree not to compromise the security of the website or attempt
            to gain access to secured areas of the website or attempt to access
            any sensitive information you may believe exist on the website or
            server where it is hosted.
          </Typography>
          <Typography variant="subtitle1" sx={{ my: 2 }}>
            You agree to be fully responsible for any claim, expense, losses,
            liability, costs including legal fees incurred by us arising from
            any infringement of the terms and conditions in this agreement and
            to which you will have agreed if you continue to use the website.
          </Typography>
        </Container>
      </Box>
    </div>
  );
};

export default Termscondition;
